<template>
  <div>
    <el-dialog title="查看子订单" width="97%" :visible.sync="dialogTableVisible" @close="closeDialog">
       <el-table :data="packageData" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}">
        <!-- <el-table-column label="序号" width="70">
          <template slot-scope="">
            <span>{{scope.$index+(packageData.current_page - 1) * packageData.per_page + 1}}</span>
          </template>
        </el-table-column> -->
          <el-table-column label="#" type="index"></el-table-column>
        <el-table-column label="订单ID" prop="order_id" width="70px"></el-table-column>
        <el-table-column label="第三方单号" prop="third_order_no" width="180px"></el-table-column>
                <el-table-column label="商品名称" prop="goods_name" width="160px"></el-table-column>
        <el-table-column label="商品价格" prop="goods_price" width="90px"></el-table-column>
        <el-table-column label="长度/米" prop="goods_length" width="70px"></el-table-column>
        <el-table-column label="宽度/米" prop="goods_width" width="70px"></el-table-column>
        <el-table-column label="商品数量" prop="goods_num" width="80px"></el-table-column>
        <el-table-column label="商品图片" > <template slot-scope="scope">
            <div class="product-info">
              <el-image :src="url+scope.row.goods_images" :preview-src-list="[url+scope.row.goods_images]"> </el-image>
            </div>
          </template>
        </el-table-column>
         <el-table-column label="收货地址" width="180px">
          <template slot-scope="scope">
            <span>{{scope.row.receive_province + scope.row.receive_city + scope.row.receive_town + scope.row.receive_address}}</span>
          </template>
        </el-table-column>
        <el-table-column label="快递公司" prop="deliver_name" width="100px"></el-table-column>
        <el-table-column label="快递费" prop="deliver_price" width="90px"></el-table-column>
        <el-table-column label="物流编号" prop="waybill_code" width="180px" id="form_tone"> </el-table-column>
        <el-table-column label="发货时间" width="100px" >
          <template slot-scope="scope"><span>{{timeConversion(scope.row.updatetime)}}</span></template>
        </el-table-column>
        <el-table-column label="操作">
           <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleCopy(scope.$index, scope.row)" type="info">复制快递单号</el-button>
        </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <!-- <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="packageData.per_page"
          layout="prev, pager, next"
          :current-page="packageData.current_page"
          :total="packageData.total">
        </el-pagination>
      </div> -->
      <span slot="footer">
        <el-button v-if="!showDownload" type="primary" size="small">下载</el-button>
        <a class="download" v-else :href="url+'/api/order/excel_order?id=' + id"><span>下载</span></a>
        <el-button @click="dialogTableVisible = false" size="small">取消</el-button>
        <el-button @click="dialogTableVisible = false" type="primary" size="small">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { timeConversion } from 'utils/utils'
export default {
  data () {
    return {
      dialogTableVisible: false,
      url: '',
      tableData: {
        waybill_code: ''
      },
      copyData: null
    }
  },
  props: {
    showDialog: { default: false },
    packageData: Array,
    id: Number,
    showDownload: Boolean
  },
  computed: {
    // 使用时间戳转化函数
    timeConversion () {
      return timeConversion
    }
  },
  created () {
    this.url = this.$url
  },
  methods: {
    // 监听关闭
    closeDialog () {
      this.$emit('closeDialog')
    },
    currentChange (val) {
      this.$emit('updatePackageData', val)
    },
    // 复制快递单号
    handleCopy(index, row) {
      this.copyData = row.waybill_code
      this.copy(this.copyData)
    },
    copy(data) {
      const url = data
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      // console.log(oInput.value)
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '快递单号复制成功',
        type: 'success'
      })
      oInput.remove()
    }
  },
  watch: {
    showDialog (val) {
      this.dialogTableVisible = val
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/childTable.css">
</style>
